import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

function LoginPage() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showSignupForm, setShowSignupForm] = useState(false);
  const navigate = useNavigate();

  // Formatar telefone automaticamente
  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    const formattedPhone = input
      .replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3') // Aplica o formato (99) 99999-9999
      .replace(/^(\d{2})(\d{4})/, '($1) $2'); // Formato intermediário enquanto digita
    setPhone(formattedPhone);
  };

  const handleSignupSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert('As senhas não coincidem!');
      return;
    }

    const signupData = {
      email: email,
      username: user.toLowerCase(),
      password: password,
      confirmed_password: confirmPassword,
      phone: phone,
    };

    try {
      const response = await fetch('https://panel.zepesquisa.com.br/accounts/users/create-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signupData),
      });

      if (!response.ok) {
        throw new Error('Erro no cadastro');
      }

      alert('Cadastro bem-sucedido! Agora, você pode fazer login.');
      setShowSignupForm(false);
      navigate('/login');
    } catch (error) {
      alert('Erro ao cadastrar, tente novamente.');
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const loginData = {
      username: user.toLowerCase(),
      password: password,
    };

    try {
      const response = await fetch('https://panel.zepesquisa.com.br/api/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        throw new Error('Erro na autenticação');
      }

      const data = await response.json();
      console.log(data);

      alert('Login bem-sucedido!');

      localStorage.setItem('access', data.access);
      localStorage.setItem('refresh', data.refresh);

      navigate('/home');
    } catch (error) {
      alert('Usuário ou senha incorretos. Tente novamente.');
    }
  };

  return (
    <div className="login-container">
      <div className={`login-box ${showSignupForm ? 'signup-box' : ''}`}>
        <h1 className="login-title">ZéPesquisa</h1>

        {/* Formulário de Login */}
        {!showSignupForm ? (
          <>
            <form onSubmit={handleLoginSubmit}>
              <div className="input-group">
                <label htmlFor="user">Email:</label>
                <input
                  type="text"
                  id="user"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Senha:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-button">Login</button>
            </form>
            <button
              className="toggle-form-button"
              onClick={() => setShowSignupForm(true)}
            >
              Ainda não tem uma conta? Cadastre-se
            </button>
          </>
        ) : null}

        {/* Formulário de Cadastro */}
        {showSignupForm ? (
          <>
            <form onSubmit={handleSignupSubmit}>
              <div className="input-group">
                <label htmlFor="email">E-mail:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="phone">Telefone:</label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                  placeholder="(99) 99999-9999"
                  maxLength={15}
                />
              </div>
              <div className="input-group">
                <label htmlFor="user">Nome de usuário:</label>
                <input
                  type="text"
                  id="user"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Senha:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="confirmPassword">Confirmar Senha:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="signup-button">Cadastrar</button>
            </form>
            <button
              className="back-to-login-button"
              onClick={() => setShowSignupForm(false)}
            >
              Já tem uma conta? Voltar para o login
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default LoginPage;
