import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import '../styles/ConsultarPage.css';

function ConsultarPage() {
  const [consultas, setConsultas] = useState([]);
  const [selectedConsulta, setSelectedConsulta] = useState('');
  const [queryParams, setQueryParams] = useState([]);
  const [paramValues, setParamValues] = useState({});
  const [consultaCost, setConsultaCost] = useState(null); // Novo estado para o cost
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchConsultas = async () => {
      try {
        const response = await authFetch('https://panel.zepesquisa.com.br/api/list-endpoints/');
        if (!response.ok) throw new Error('Erro ao buscar consultas');
        const data = await response.json();
        setConsultas(Object.entries(data.available_endpoints));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchConsultas();
  }, []);

  const handleConsultaChange = (e) => {
    const selected = e.target.value;
    setSelectedConsulta(selected);

    const selectedEndpoint = consultas.find(([key]) => key === selected);
    if (selectedEndpoint) {
      const { params, cost } = selectedEndpoint[1]; // Extraindo params e cost
      setQueryParams(params);
      setConsultaCost(cost); // Atualizando o cost no estado
      setParamValues(params.reduce((acc, param) => ({ ...acc, [param]: '' }), {}));
    }
  };

  const handleParamChange = (param, value) => {
    setParamValues((prevValues) => ({
      ...prevValues,
      [param]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    setResult(null);

    const selectedEndpoint = consultas.find(([key]) => key === selectedConsulta);
    console.log("selectedEndpoint", selectedEndpoint)

    if (!selectedEndpoint) {
      setError('Consulta selecionada não encontrada.');
      setSubmitting(false);
      return;
    }

    const name = selectedEndpoint;

    const payload = {
      name,
      params: paramValues,
    };

    try {
      const response = await authFetch('https://panel.zepesquisa.com.br/api/fetch-registered-apis/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Erro ao realizar a consulta');
      } else {
        const result = await response.text();
        setResult(result);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleVoltar = () => {
    setSelectedConsulta('');
    setParamValues({});
    setConsultaCost(null); // Resetando o cost
    setError(null);
    setResult(null);
  };

  const handleSaveAsPDF = () => {
    const doc = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.text('Resultado da Consulta', 30, 40);

    const resultContainer = document.querySelector('.result-container');
    const savePdfButton = resultContainer.querySelector('.save-pdf-btn');
    const voltarButton = resultContainer.querySelector('.voltar-btn');

    // Cria a mensagem de "Salvando PDF"
    const savingMessage = document.createElement('div');
    savingMessage.textContent = 'Aguarde... Salvando PDF.';
    savingMessage.style.position = 'fixed';
    savingMessage.style.top = '50%';
    savingMessage.style.left = '50%';
    savingMessage.style.transform = 'translate(-50%, -50%)';
    savingMessage.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    savingMessage.style.color = '#fff';
    savingMessage.style.padding = '20px';
    savingMessage.style.borderRadius = '5px';
    savingMessage.style.zIndex = '1000';
    document.body.appendChild(savingMessage);

    // Oculta os botões "Salvar como PDF" e "Voltar"
    savePdfButton.style.display = 'none';
    voltarButton.style.display = 'none';

    html2canvas(resultContainer, { scale: 1.5, useCORS: true, logging: false }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 500;
        const pageHeight = doc.internal.pageSize.height;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 60;

        doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
        heightLeft -= pageHeight - 60;

        while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 30, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(8);
            doc.setTextColor(150);
            doc.text(`Página ${i} de ${pageCount}`, doc.internal.pageSize.getWidth() - 60, doc.internal.pageSize.getHeight() - 30);
        }

        // Restaura os botões "Salvar como PDF" e "Voltar"
        savePdfButton.style.display = 'block';
        voltarButton.style.display = 'block';

        // Remove a mensagem de "Salvando PDF"
        document.body.removeChild(savingMessage);

        doc.save('resultado_consulta.pdf');
    }).catch((error) => {
        console.error('Erro ao salvar PDF:', error);
        document.body.removeChild(savingMessage); // Remove a mensagem em caso de erro
        savePdfButton.style.display = 'block';
        voltarButton.style.display = 'block';
    });
  };

  return (
    <div className="consultar-container">
      <h2>Consultar</h2>
      {loading && <div>Carregando...</div>}
      {!loading && error && (
        <div className="error-container">
          <div className="error-message">Erro: {error}</div>
          <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
        </div>
      )}
      {!loading && !error && (
        <>
          <form onSubmit={handleSubmit} className="recarga-form">
            <label htmlFor="consulta">Escolha a consulta:</label>
            <select
              id="consulta"
              value={selectedConsulta}
              onChange={handleConsultaChange}
              required
            >
              <option value="" disabled>Selecione uma consulta</option>
              {consultas.length > 0 ? (
                consultas.map(([key]) => (
                  <option key={key} value={key}>
                    {key.replace(/-/g, ' ')}
                  </option>
                ))
              ) : (
                <option>Nenhuma consulta disponível</option>
              )}
            </select>

            {queryParams.map((param, index) => (
              <div key={index} className="param-container">
                <label htmlFor={`param-${param}`} className="param-label"><strong>{param}:</strong></label>
                <input
                  type="text"
                  id={`param-${param}`}
                  placeholder={`Digite o valor para ${param}`}
                  value={paramValues[param] || ''}
                  onChange={(e) => handleParamChange(param, e.target.value)}
                  required
                />
                {/* Contêiner para o campo de Valor */}
                <div className="value-container">
                  <label htmlFor={`value-${param}`} className="value-label"><strong>Valor:</strong></label>
                  <input
                    type="text"
                    id={`value-${param}`}
                    className="cost-field"
                    readOnly
                    value={consultaCost !== null ? `R$ ${consultaCost.toFixed(2)}` : ''}
                    placeholder="R$ 0,00"
                  />
                </div>
              </div>
            ))}


            <button type="submit" className="generate-pix-btn" disabled={submitting}>
              {submitting ? 'Carregando...' : 'Consultar'}
            </button>
          </form>
          {result && (
            <div className="result-container">
              <h3>Resultado da Consulta</h3>
              <div dangerouslySetInnerHTML={{ __html: result }} />
              <div className="result-buttons">
                <button onClick={handleSaveAsPDF} className="save-pdf-btn">Salvar como PDF</button>
                <button onClick={handleVoltar} className="voltar-btn">Voltar</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ConsultarPage;
