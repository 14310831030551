import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch';
import '../styles/HomePage.css';
import logo from '../assets/images/logo.jpg';
import RecargaPage from './RecargaPage';
import ConsultarPage from './ConsultarPage';
import ApisPage from './ApisPage';
import ApisJsonPage from './ApisJsonPage';
import PainelPage from './PainelPage';
import SuperuserPage from './SuperuserPage';

function HomePage() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [activeContent, setActiveContent] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [, setIsSuperuser] = useState(false);

  // Função para buscar os usuários da API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await authFetch('https://panel.zepesquisa.com.br/accounts/users/');
        if (!response.ok) {
          throw new Error('Erro ao buscar usuários');
        }
  
        const result = await response.json();
        setUsers(result);
  
        // Verifica se o usuário logado é superusuário
        const superuser = result.find(user => user.is_superuser);
        if (superuser) {
          setIsSuperuser(true);
          setActiveContent('Superuser');
        }
      } catch (err) {
        setError(err.message);
      }
    };
  
    fetchUsers();
  }, []);

  // Função de logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  // Função para alternar a visibilidade da sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Renderiza o conteúdo dinâmico com base no menu selecionado
  const renderActiveContent = () => {
    switch (activeContent) {
      case 'Painel':
        return <PainelPage />;
      case 'APIs HTML':
        return <ApisPage />;
      case 'APIs JSON':
        return <ApisJsonPage />;
      case 'Recarga':
        return <RecargaPage />;
      case 'Consulta':
        return <ConsultarPage />;
      case 'Superuser':
        return <SuperuserPage />;
      default:
        return null;
    }
  };

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <>
      <div className={`home-page-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Botão para abrir a barra lateral no mobile */}
        <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
          {sidebarOpen ? 'Fechar Menu' : 'Abrir Menu'}
        </button>

        {/* Barra lateral com a imagem e botões verticais */}
        <div className={`home-sidebar ${sidebarOpen ? 'open' : ''}`}>
          <img src={logo} alt="Logo" className="sidebar-logo" />
          {['Consulta', 'Painel', 'APIs HTML', 'APIs JSON', 'Recarga'].map(menu => (
            <button
              key={menu}
              className={activeContent === menu ? 'active' : ''}
              onClick={() => { setActiveContent(menu); setSidebarOpen(false); }}
            >
              {menu}
            </button>
          ))}
        </div>

        {/* Barra de informações do usuário na parte superior */}
        <div className="main-content">
          <div className="user-info-horizontal">
            <div className="user-list">
              {users.length > 0 ? (
                <ul>
                  {users.map(user => (
                    <li key={user.id}>
                      <strong>Email:</strong> {user.email} <br />
                      <strong>Saldo:</strong> {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(user.balance)}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Carregando usuários...</p>
              )}
            </div>

            {/* Botões de logout e criar login */}
            <div className="user-actions">
              <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
          </div>

          {/* Conteúdo dinâmico renderizado */}
          <div className="dynamic-content">
            {renderActiveContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
